<template>
  <section>
    <div class="d-flex justify-content-between mt-5">
      <div class="">
        <span class="f16 fw-bold">{{ $t("Activity status") }}</span>
      </div>
      <div>
        <v-select
          :items="option"
          mandatory
          v-model="selected"
          solo
          dense
          class="rounded-pill fit"
          hide-details
        >
        </v-select>
      </div>
    </div>
    <div>
      <div class="mt-2">
        <LineChartGenerator
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </div>
    </div>

    <div class="d-flex align-items-center mt-2" v-for="(mood, index) in moods" :key="index">
          <v-avatar class="my-2" size="96" rounded="circle">
            <v-carousel hide-delimiters cycle :show-arrows="false" :interval="2000" disabled>
              <v-carousel-item v-for="(t, i) in mood.moodTypes" :key="i" transition="scale-transition" reverse-transition="scale-transition">
                <img class="img-fluid pa-5" :src="t.resourceId"/>
              </v-carousel-item>
            </v-carousel>
          </v-avatar>
          <div class="me-2 ms-0">
            <span class="d-block  f11 grey--text text--darken-1">
              {{ mood.updateDate | moment("dddd , jD jMMMM jYYYY") }}
            </span>
            <span class="f14 font-weight-bold my-1" v-for="(t, i) in mood.moodTypes">
              {{ $t(t.title) }}{{i != mood.moodTypes.length-1 ? "، " : ""}}
            </span>
            <span class="f13 d-block break-words">
              {{ mood.description }}
            </span>
          </div>

          <v-btn 
            color="accent"   
            class="pa-1 ms-auto me-0"
            outlined
            icon
            @click="deleteMood(mood)"
          >
            <i class="fal fa-trash-alt p-1"></i>
          </v-btn>
    </div>

    <v-footer app fixed padless color="transparent" class="mb-2">
      <v-container fluid class="py-0 my-0 d-flex justify-content-end">
        <v-btn color="primary" large rounded @click="openMoodBottomSheet"
          >{{ $t("Add") }} +
        </v-btn>
      </v-container>
    </v-footer>


    <vue-bottom-sheet ref="moodBottomSheet">
      <section class="d-flex flex-column   justify-content-center">
        <span class="my-1 d-block text-center f15">{{ $t('Choose one') }}</span>
        
        <v-slide-group show-arrows multiple v-model="selectedMoodTypes">
          <v-slide-item v-for="(item, i) in moodTypes" :key="i" v-slot="{ active, toggle }">
            <v-card flat class="ma-4 text-center" @click="toggle">
              <v-avatar size="48" :class="active ? 'border border-3 border-red border-dotted' : ''">
                <v-img :src="item.resourceId"></v-img>
              </v-avatar>
              <span class="f12 max-3-line mt-1">
                {{ $t(item.title) }}
              </span>
            </v-card>
          </v-slide-item>
        </v-slide-group>

        <v-textarea outlined v-model="moodDescription" :rules="moodRules" :counter="255" :placeholder="$t('Describe your mental state')" type="text" class="f13" auto-grow/>

        <div class="mb-4 w-100">
          <v-btn block color="primary" @click="submitMood" :loading="submit_loading" :disabled="submit_loading" rounded>{{ $t('Submit') }}</v-btn>
        </div>
      </section>
    </vue-bottom-sheet>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from 'moment-jalaali';
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian({ dialect: 'persian-modern' });


import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  Filler,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      type: String,
      default: "",
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return {
      submit_loading: false,
      user: {},
      username: null,
      moods: [],
      chartLables: [],
      chartValues:[],
      moodTypes: [],
      option: [{ value: "week", text: this.$t("Week") }, { value: "month", text: this.$t("Month") }, { value: "year", text: this.$t("Year") }, { value: "signup", text: this.$t("Signup") }],
      selected: "week",

      selectedMoodTypes: [],
      moodDescription: '',
      inputOtherReason: '',
      moodOtherReason: '',

      moodRules: [
        v => !!v,
        v => (v && v.length <= 255) || this.$t("The maximum length is 255 characters")
      ],
    }
  },
  computed: {
    chartData() { 
      return {
        labels: this.chartLables,
        datasets: [
          {
            label: this.$t("Mood status"),
            backgroundColor: ["#20B1EA"],
            data: this.chartValues,
            radius: 5,
            borderWidth: 2,
            hoverBorderWidth: 10,
            fill: {
                target: 'origin',
                above: '#2196F325',
                below: '#EF535025'
            },
            pointStyle: 'rectRounded',
            pointBackgroundColor: function(context) {
              var index = context.dataIndex;
              var value = context.dataset.data[index];
              return value < 0 ? '#EF5350' :  '#2196F3';
            },
            tension: 0.33
          },
        ],
      }
    },
    chartOptions() { 
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 1,
              display: true,
              font: {
                size: 12,
                family: "IRANSans",
              },
              callback: function(value, index, ticks) {
                if(value >= 0) {
                  return value;
                }
                else {
                  return Math.abs(value) + '-'
                }
              }
            },
          },

          x: {
            ticks: {
              minRotation: 45,
              font: {
                size: 12,
                family: "IRANSans",
              },
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 13,
                family: "IRANSans",
              },
            },
          },
          tooltip: {
            enabled: true,
            titleFont: {
              size: 10,
              family: "IRANSans",
            },
            bodyFont: {
              size: 11,
              family: "IRANSans",
            },

            footerFont: {
              size: 12,
              family: "IRANSans",
            },
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                if (context.parsed.y !== null) {
                  label = "تعداد: " + Math.abs(context.parsed.y);
                }
                return label;
              },
            },
          },
        },
      }
    },
  },
  methods: {
    openMoodBottomSheet(){
      this.$refs.moodBottomSheet.open()
    },
    rand(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    getClientUserMoodsWeek(){
      var now = new moment();
      var end = now.format("YYYY-MM-DD");
      var start = now.subtract(6, "days").format("YYYY-MM-DD");


      this.chartLables = [];
      this.chartValues = [];

      for (let i = 6; i >= 0; i--) {
        this.chartLables.push(moment().subtract(i, "days").format("ddd"));
      }

      apiService.getClientUserMoods(start, end)
        .then((response) => {
          this.moods = [];
          response.data.moods.forEach(element => {
            element.moodTypes = this.setMoodStatus(element);
            this.moods.push(element);
          });
          this.initChartByPeriodTypeAndData('week', this.moods);
        })
        .catch((err) => {
          console.log(err)
          this.$router.go(-1);
        })
    },
    getClientUserMoodsMonth(){
      var now = new moment();
      var end = now.format("YYYY-MM-DD");

      
      var shStart = now.startOf('jMonth').format('jYYYY-jMM-jDD');
      var start = moment(shStart, "jYYYY-jMM-jDD").format("YYYY-MM-DD") 


      this.chartLables = [];
      this.chartValues = [];
      const today = moment().jDate();
      for (let i = 1; i <= today; i++) {
        this.chartLables.push(i);
      }
      
      apiService.getClientUserMoods(start, end)
        .then((response) => {
          this.moods = [];
          response.data.moods.forEach(element => {
            element.moodTypes = this.setMoodStatus(element);
            this.moods.push(element);
          });
          
          this.initChartByPeriodTypeAndData('month', this.moods);
        })
        .catch((err) => {
          console.log(err)
          this.$router.go(-1);
        })
    },
    getClientUserMoodsYear(){
      var now = new moment();
      var end = now.format("YYYY-MM-DD");

      var shStart = now.startOf('jYear').format('jYYYY-jMM-jDD')
      var start = moment(shStart, "jYYYY-jMM-jDD").format("YYYY-MM-DD") 


      this.chartLables = [];
      this.chartValues = [];
      const today = moment().jMonth();
      for (let i = 0; i <= today; i++) {
        this.chartLables.push(moment().startOf('jYear').add(i, "jMonth").format('jMMMM'));
      }

      apiService.getClientUserMoods(start, end)
        .then((response) => {
          this.moods = [];
          response.data.moods.forEach(element => {
            element.moodTypes = this.setMoodStatus(element);
            this.moods.push(element);
          });
          this.initChartByPeriodTypeAndData('year', this.moods);
        })
        .catch((err) => {
          console.log(err)
          this.$router.go(-1);
        })
    },
    getClientUserMoodsSignup(){
      var now = new moment();
      var end = now.format("YYYY-MM-DD");
      var start = moment(this.user.createDate).format("YYYY-MM-DD") 


      this.chartLables = [];
      this.chartValues = [];
      const diff = Math.ceil(moment().diff(moment(this.user.createDate), "months", true)) ;
      for (let i = 0; i <= diff; i++) {
        this.chartLables.push(moment(this.user.createDate).add(i, "jMonth").format('jYYYY jMMMM'));
      }

      apiService.getClientUserMoods(start, end)
        .then((response) => {
          this.moods = [];
          response.data.moods.forEach(element => {
            element.moodTypes = this.setMoodStatus(element);
            this.moods.push(element);
          });
          this.initChartByPeriodTypeAndData('signup', this.moods);
        })
        .catch((err) => {
          console.log(err)
          this.$router.go(-1);
        })
    },
    getUserInformationByPhoneNumber() {
      apiService
        .getUserInformationByPhoneNumber(this.$store.getters.mobileNumber)
        .then((response) => {
          this.user = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setupMoodTypes() {
      var moodGood = {
        title: "Fine",
        index: 1,
        resourceId: require('@/assets/images/app/moods/ic_mood_good.svg'),
        backgroundColor: "#fef9f6",
        borderColor: "#db7e6c",
        shadowColor: "#50db7e6c",
      }

      var moodHopeless = {
        title: "Disappointed",
        index: 2,
        resourceId: require('@/assets/images/app/moods/ic_mood_hopeless.svg'),
        backgroundColor: "#fff9f3",
        borderColor: "#ff8000",
        shadowColor: "#50ff8000",
      }

      var moodWorried = {
        title: "Worried",
        index: 3,
        resourceId: require('@/assets/images/app/moods/ic_mood_worred.svg'),
        backgroundColor: "#fef9f6",
        borderColor: "#db7e6c",
        shadowColor: "#50db7e6c",
      }

      var moodStress = {
        title: "Stress",
        index: 4,
        resourceId: require('@/assets/images/app/moods/ic_mood_stress.svg'),
        backgroundColor: "#f7f7ff",
        borderColor: "#a29bfe",
        shadowColor: "#20a29bfe",
      }

      var moodSad = {
        title: "Sad",
        index: 5,
        resourceId: require('@/assets/images/app/moods/ic_mood_sad.svg'),
        backgroundColor: "#fff8f8",
        borderColor: "#e8252b",
        shadowColor: "#50e8252b",
      }

      var moodNervous = {
        title: "Nervous",
        index: 6,
        resourceId: require('@/assets/images/app/moods/ic_mood_upset.svg'),
        backgroundColor: "#fbfdff",
        borderColor: "#a0a5aa",
        shadowColor: "#50a0a5aa",
      }

      var moodSleepGood = {
        title: "Slept well",
        index: 7,
        resourceId: require('@/assets/images/app/moods/ic_mood_good_sleep.svg'),
        backgroundColor: "#f7f7ff",
        borderColor: "#a29bfe",
        shadowColor: "#20a29bfe",
      }

      var moodSleepBad = {
        title: "Slept badly",
        index: 8,
        resourceId: require('@/assets/images/app/moods/ic_mood_bad_sleep.svg'),
        backgroundColor: "#fff9f3",
        borderColor: "#ff8000",
        shadowColor: "#50ff8000",
      }

      var moodOther = {
        title: "Other",
        index: 9,
        resourceId: require('@/assets/images/app/moods/ic_mood_other.svg'),
        backgroundColor: "#ebffff",
        borderColor: "#00cec9",
        shadowColor: "#5000cec9",
      }

      this.moodTypes.push(moodGood);
      this.moodTypes.push(moodHopeless);
      this.moodTypes.push(moodWorried);
      this.moodTypes.push(moodStress);
      this.moodTypes.push(moodSad);
      this.moodTypes.push(moodNervous);
      this.moodTypes.push(moodSleepGood);
      this.moodTypes.push(moodSleepBad);
      this.moodTypes.push(moodOther);
    },
    setMoodStatus(mood) {
      var moodTypeList = [];

      if (mood.reason0 == '0' &&
        mood.reason1 == '0' &&
        mood.reason2 == '0' &&
        mood.reason3 == '0' &&
        mood.reason4 == '0' &&
        mood.reason5 == '0' &&
        mood.reason6 == '0' &&
        mood.reason7 == '0'
      ) {
        moodTypeList.push(this.moodTypes[0]);
      }

      if (mood.reason0 == '1') {
        moodTypeList.push(this.moodTypes[1]);
      }
      if (mood.reason1 == '1') {
        moodTypeList.push(this.moodTypes[2]);
      }
      if (mood.reason2 == '1') {
        moodTypeList.push(this.moodTypes[3]);
      }
      if (mood.reason3 == '1') {
        moodTypeList.push(this.moodTypes[4]);
      }
      if (mood.reason4 == '1') {
        moodTypeList.push(this.moodTypes[5]);
      }
      if (mood.reason5 == '1') {
        moodTypeList.push(this.moodTypes[6]);
      }
      if (mood.reason6 == '1') {
        moodTypeList.push(this.moodTypes[7]);
      }
      if (mood.reason7 == '1') {
        moodTypeList.push(this.moodTypes[8]);
      }

      return moodTypeList;
    },
    initChartByPeriodTypeAndData(periodType, moods) {
      switch (periodType) {        
        case 'month': {
          var count = 0;
          for (var i = 0; i < this.chartLables.length; i++) {
            count = this.getCountOfMoodsSubmittedInThisDate(moods, i, periodType);
            this.chartValues.push(count);
          }
          
          break;
        }

        case 'year': {
          var count = 0;
          for (var i = 0; i < this.chartLables.length; i++) {
            count = this.getCountOfMoodsSubmittedInThisDate(moods, i, periodType);
            this.chartValues.push(count);
          }

          break;
        }
        case 'signup': {
          var count = 0;
          for (var i = 0; i < this.chartLables.length; i++) {
            count = this.getCountOfMoodsSubmittedInThisDate(moods, this.chartLables[i], periodType);
            this.chartValues.push(count);
          }

          break;
        }
        default: {
          var count = 0;
          for (var i = 0; i < this.chartLables.length; i++) {
            count = this.getCountOfMoodsSubmittedInThisDate(moods, this.chartLables[i], periodType);
            this.chartValues.push(count);
          }

          break;
        }
      }
    },
    getCountOfMoodsSubmittedInThisDate(moods, labelIndex, periodType){
      switch (periodType) {        
        case 'month': {
          var count = 0;
          for (const mood of moods) {
            if (mood.reason0 != 0 ||
              mood.reason1 != 0 ||
              mood.reason2 != 0 ||
              mood.reason3 != 0 ||
              mood.reason4 != 0 ||
              // mood.reason5 != 0 ||
              mood.reason6 != 0 ||
              mood.reason7 != 0
            ) {
              var pdate = moment(mood.createDate);
              if (pdate.jDate() == labelIndex + 1) {
                count--;
              }
            } else {
              var pdate = moment(mood.createDate);
              if (pdate.jDate() == labelIndex + 1) {
                count++;
              }
            }
          }

          return count;
        }

        case 'year': {
          var count = 0;
          for (const mood of moods) {
            if (mood.reason0 != 0 ||
              mood.reason1 != 0 ||
              mood.reason2 != 0 ||
              mood.reason3 != 0 ||
              mood.reason4 != 0 ||
              // mood.reason5 != 0 ||
              mood.reason6 != 0 ||
              mood.reason7 != 0
            ) {
              var pdate = moment(mood.createDate);
              if (pdate.jMonth() == labelIndex) {
                count--;
              }
            } else {
              var pdate = moment(mood.createDate);
              if (pdate.jMonth() == labelIndex) {
                count++;
              }
            }
          }

          return count;
        }

        case 'signup': {
          var count = 0;
          for (const mood of moods) {
            if (mood.reason0 != 0 ||
              mood.reason1 != 0 ||
              mood.reason2 != 0 ||
              mood.reason3 != 0 ||
              mood.reason4 != 0 ||
              // mood.reason5 != 0 ||
              mood.reason6 != 0 ||
              mood.reason7 != 0
            ) {
              var pdate = moment(mood.createDate);
              if (pdate.format('jYYYY jMMMM') == labelIndex) {
                count--;
              }
            } else {
              var pdate = moment(mood.createDate);
              if (pdate.format('jYYYY jMMMM') == labelIndex) {
                count++;
              }
            }
          }

          return count;
        }
        default: {
          var count = 0;
          for (const mood of moods) {
            if (mood.reason0 != 0 ||
              mood.reason1 != 0 ||
              mood.reason2 != 0 ||
              mood.reason3 != 0 ||
              mood.reason4 != 0 ||
              // mood.reason5 != 0 ||
              mood.reason6 != 0 ||
              mood.reason7 != 0
            ) {
              var pdate = moment(mood.createDate);
              if (pdate.format('ddd') == labelIndex) {
                count--;
              }
            } else {
              var pdate2 = moment(mood.createDate);
              if (pdate2.format('ddd') == labelIndex) {
                count++;
              }
            }
          }

          // if (moods.length == 0) {
          //   count = randCount();
          // }

          return count;
        }
      }
    },
    submitMood(){
      var moodDTO = {
        userMobile: this.$store.getters.mobileNumber,
        reason0: 0,
        reason1: 0,
        reason2: 0,
        reason3: 0,
        reason4: 0,
        reason5: 0,
        reason6: 0,
        reason7: 0,
        otherReason: '',
        description: this.moodDescription
      }
      
      this.selectedMoodTypes.forEach(element => {
        switch (element) {
          case 0:
            //خوب

            break;
          case 1:
            //ناامیدم
            moodDTO.reason0 = 1;
            break;
          case 2:
            //نگرانم
            moodDTO.reason1 = 1;
            break;
          case 3:
            //استرس
            moodDTO.reason2 = 1;
            break;
          case 4:
            //غمگینم
            moodDTO.reason3 = 1;
            break;
          case 5:
            //عصبی ام
            moodDTO.reason4 = 1;
            break;
          case 6:
            //خوب خوابیدم
            moodDTO.reason5 = 1;
            break;
          case 7:
            //بد خوابیدم
            moodDTO.reason6 = 1;
            break;
          case 8:
            //Other Reason
            moodDTO.reason7 = 1;
            break;         
        }
      });

      if (moodDTO.reason7 == 1) {
        moodDTO.otherReason = this.moodOtherReason;
      }
      else {
        moodDTO.otherReason = '';
      }

      apiService.addMood(moodDTO)
        .then(() => {
          this.submit_loading = false;
          this.$refs.moodBottomSheet.close();
          if(this.selected == 'week') {
            this.getClientUserMoodsWeek()
          }
          else if (this.selected == 'month') {
            this.getClientUserMoodsMonth()
          }
          else if (this.selected == 'year'){
            this.getClientUserMoodsYear()
          }
          else if (this.selected == 'signup'){
            this.getClientUserMoodsSignup()
          }
          this.resetMoodDialog();
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$refs.moodBottomSheet.close()
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });

    },
    resetMoodDialog(){
      this.selectedMoodTypes= [];
      this.moodDescription= '';
      this.inputOtherReason= '';
      this.moodOtherReason= '';
    },
    deleteMood(mood) {
      this.$swal({
          icon: "warning",
          showDenyButton: true,
          text: this.$t("Are you sure about removing the mood?"),
          confirmButtonText: this.$t('Yes'),
          denyButtonText: this.$t('No'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            apiService.deleteMood(mood.id)
            .then(() => {
              if(this.selected == 'week') {
                this.getClientUserMoodsWeek()
              }
              else if (this.selected == 'month') {
                this.getClientUserMoodsMonth()
              }
              else if (this.selected == 'year'){
                this.getClientUserMoodsYear()
              }
              else if (this.selected == 'signup'){
                this.getClientUserMoodsSignup()
              }
            })
            .catch((err) => {
              this.$swal({
                icon: "warning",
                text: err ? err.response.data.detail : this.$t("Error"),
                confirmButtonText: this.$t('OK'),
              });
            });
          }
        });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    this.setupMoodTypes();
  },
  mounted(){
    this.getClientUserMoodsWeek();
    this.getUserInformationByPhoneNumber();
  },
  watch: {
    selectedMoodTypes: function(val, old){
      if (val.includes(8) && !old.includes(8)) {
        this.$swal({
          title: this.$t('Other cases'),
          input: 'text',
          inputLabel: this.$t('Other than the above, describe your mental state with a word or words'),
          inputValue: this.inputOtherReason,
          inputAttributes: {
            'dir': this.$vuetify.rtl ? 'rtl' : 'ltr',
            'max' : 255,
            'min' : 3
          },
          showCancelButton: true,
          confirmButtonText: this.$t('Submit'),
          cancelButtonText: this.$t('Cancel'),
          inputValidator: (value) => {
            if (!value) {
              return this.$t('You need to write something')
            }
          }
        })
        .then((result) => {
          if (result.isConfirmed && result.value) {
            this.moodOtherReason = result.value;
          }
        });
      }
    },
    selected: function () {
      if(this.selected == 'week') {
        this.getClientUserMoodsWeek()
      }
      else if (this.selected == 'month') {
        this.getClientUserMoodsMonth()
      }
      else if (this.selected == 'year'){
        this.getClientUserMoodsYear()
      }
      else if (this.selected == 'signup'){
        this.getClientUserMoodsSignup()
      }
    },
  },
}
</script>
